import React from "react"
import PropTypes from "prop-types"
import { graphql, Link, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { FaBars, FaFacebookSquare, FaInstagram, FaYoutube } from "react-icons/fa"

import "./layout.css"
import "./menu.css"

const Partners = styled.section`
  background: hsla(0, 0%, 95%, 1);
  padding: 26px 0 0;

  h2 {
    font-size: 32px;
    line-height: 38px;
    position: relative;
    text-align: center;
    top: -8px;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    flex-flow: center;
    justify-content: space-evenly;
    list-style-type: none;
    margin: 0 auto;
    max-width: 1372px;
    padding: 0 5px;
  }

  li {
    flex: 50%;
    max-width: 50%;
  }

  @media (min-width: 768px) {
    padding-top: 40px 0 0;

    h2 {
      font-size: 83px;
      line-height: 90px;
      top: -20px;
    }

    ul {
      padding: 0 120px;
    }

    li {
      flex: 33%;
      max-width: 33%;
    }
  }
`

const PartnerImg = styled(Img)`
  height: 172px;
  margin: 0 auto;
  width: 172px;

  @media (min-width: 768px) {
    height: 192px;
    width: 192px;
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 568px) {
    height: 120px;
    width: 120px;
  }
`

const MyImg = styled(Img)`
  height: 38px;
  margin: 0 auto;
  width: 48px;

  @media (min-width: 768px) {
    height: 90px;
    width: 113px;
  }
`

const FootNote = styled.section`
  background: hsla(240, 3%, 14%, 1);
  color: hsla(240, 0%, 57%, 1);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 26px 0;
  text-align: center;

  a {
    color: inherit;
  }

  li {
    float: left;
    font-size: 30px;
    margin: 0 24px 0 0;
  }

  p {
    width: 100%;
  }

  ul {
    list-style-type: none;
    margin: 0 0 10px;
  }

  @media (min-width: 768px) {
    padding: 40px 0;
  }
`

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query LayoutDataQuery {
      site {
        siteMetadata {
          title
        }
      }
      myImage: file(relativePath: { eq: "my.png" }) {
        childImageSharp {
          fluid(maxWidth: 115) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      wingsOfStrengthImage: file(
        relativePath: { eq: "wings-of-strength.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 192) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      builtBarImage: file(relativePath: { eq: "built-bar.png" }) {
        childImageSharp {
          fluid(maxWidth: 192) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      aminoAllianceImage: file(relativePath: { eq: "amino-alliance.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 192) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      gymterraImage: file(relativePath: { eq: "gymterra.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 192) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      zenfitAppImage: file(relativePath: { eq: "zenfit-app.png" }) {
        childImageSharp {
          fluid(maxWidth: 192) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      myLittleBeautiqueImage: file(
        relativePath: { eq: "my-little-beautique.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 192) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mimenteImage: file(relativePath: { eq: "mimente.png" }) {
        childImageSharp {
          fluid(maxWidth: 192) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <>
      <header>
        <a
          href="#main-menu"
          id="main-menu-toggle"
          className="menu-toggle"
          aria-label="Open main menu"
        >
          <span className="sr-only">Open main menu</span>
          <FaBars style={{ verticalAlign: "text-bottom", height: "32px" }} />
        </a>
        <h1 className="logo">Helle Trevino</h1>
        <nav id="main-menu" className="main-menu" aria-label="Main menu">
          <a
            href="#main-menu-toggle"
            id="main-menu-close"
            className="menu-close"
            aria-label="Close main menu"
          >
            <span className="sr-only">Close main menu</span>
            <span className="fa fa-close" aria-hidden="true"></span>
          </a>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
          </ul>
        </nav>
        <a
          href="#main-menu-toggle"
          className="backdrop"
          tabIndex="-1"
          aria-hidden="true"
          hidden
        >&nbsp;</a>
      </header>
      <main>{children}</main>
      <footer>
        <Partners>
          <MyImg fluid={data.myImage.childImageSharp.fluid} alt="" />
          <h2>Partners</h2>
          <ul>
            <li>
              <a
                href="https://wingsofstrength.net/"
                rel="noreferrer"
                target="_blank"
              >
                <PartnerImg
                  fluid={data.wingsOfStrengthImage.childImageSharp.fluid}
                  alt="Wings of Strength"
                />
              </a>
            </li>
            <li>
              <a
                href="http://share.builtbar.com/Helle10"
                rel="noreferrer"
                target="_blank"
              >
                <PartnerImg
                  fluid={data.builtBarImage.childImageSharp.fluid}
                  alt="Built Bar"
                />
              </a>
            </li>
            <li>
              <a
                href="https://www.amino-alliance.com/helle10"
                rel="noreferrer"
                target="_blank"
              >
                <PartnerImg
                  fluid={data.aminoAllianceImage.childImageSharp.fluid}
                  alt="Amino Alliance"
                />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/gymterra_family/"
                rel="noreferrer"
                target="_blank"
              >
                <PartnerImg
                  fluid={data.gymterraImage.childImageSharp.fluid}
                  alt="GymTerra"
                />
              </a>
            </li>
            <li>
              <a
                href="https://www.mylittlebeautiquela.com/"
                rel="noreferrer"
                target="_blank"
              >
                <PartnerImg
                  fluid={data.myLittleBeautiqueImage.childImageSharp.fluid}
                  alt="My Little Beautique"
                />
              </a>
            </li>
            <li>
              <a href="https://zenfitapp.com/" rel="noreferrer" target="_blank">
                <PartnerImg
                  fluid={data.zenfitAppImage.childImageSharp.fluid}
                  alt="Zenfit"
                />
              </a>
            </li>
            <li>
              <a
                href="https://mimenteflex.com/"
                rel="noreferrer"
                target="_blank"
              >
                <PartnerImg
                  fluid={data.mimenteImage.childImageSharp.fluid}
                  alt="Mi Mente Copenhagen"
                />
              </a>
            </li>
          </ul>
        </Partners>
        <FootNote>
          <ul>
            <li>
              <a
                href="https://www.instagram.com/helletrevino/"
                rel="noreferrer"
                target="_blank"
              >
                <FaInstagram style={{ verticalAlign: "text-bottom" }} />
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/channel/UC7ZbdzhskYNdRNf6gkbeMqQ"
                rel="noreferrer"
                target="_blank"
              >
                <FaYoutube style={{ verticalAlign: "text-bottom" }} />
              </a>
            </li>
            <li>
              <a
                href="https://facebook.com/CHAMPIONHELLETREVINO"
                rel="noreferrer"
                target="_blank"
              >
                <FaFacebookSquare style={{ verticalAlign: "text-bottom" }} />
              </a>
            </li>
          </ul>
          <p>
            © {new Date().getFullYear()} Helle Trevino. All Rights Reserved.
          </p>
        </FootNote>
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
